<template>
  <div class="white radius-15 pa-2 pb-4">
    <profile-head :title="$t('dashboard.socialMedia')"></profile-head>

    <!-- contact -->
    <div class="mt-4 bluebgdash" :class="isMobile ? 'pa-3 pb-6' : 'px-8 py-6'">
      <div class="d-flex align-center justify-space-between mb-3">
        <span class="font-18 font-700">{{ $t("dashboard.contactInfo") }}</span>

        <!-- edit -->
        <div
          class="d-flex align-center cursor-pointer"
          :class="isMobile ? 'mr-1' : 'mr-7'"
          @click="contactDialog = true"
        >
          <img
            class="mx-3"
            :src="require('@/assets/images/dashboard/icons/edit.svg')"
          />
          <span class="darkprimary--text font-16 font-700">{{
            $t("forms.edit")
          }}</span>
        </div>
      </div>

      <v-row :class="isMobile ? 'mt-3' : 'px-8 mt-4'">
        <!-- email -->
        <v-col lg="2" md="3" cols="12" :class="isMobile ? 'py-1' : ''">
          <base-info
            :title="$t('dashboard.contactType')"
            :value="data.contact_type"
          ></base-info>
        </v-col>

        <!-- cv -->
        <v-col lg="2" md="3" cols="12" :class="isMobile ? 'py-1' : ''">
          <base-info
            :title="$t('dashboard.cv')"
            :value="
              data.cv ? $t('dashboard.uploaded') : $t('dashboard.notUploaded')
            "
            :isUploaded="data.cv ? true : false"
            :isFile="true"
            @click="openLink(data.cv)"
          ></base-info>
        </v-col>

        <!-- degree file -->
        <v-col lg="2" md="3" cols="12" :class="isMobile ? 'py-1' : ''">
          <base-info
            :title="$t('dashboard.degreeFile')"
            :value="
              data.degree_file
                ? $t('dashboard.uploaded')
                : $t('dashboard.notUploaded')
            "
            :isUploaded="data.degree_file ? true : false"
            :isFile="true"
            @click="openLink(data.degree_file)"
          ></base-info>
        </v-col>

        <!-- portfolio -->
        <v-col
          v-if="data.portfolio.length == 0"
          lg="2"
          md="3"
          cols="12"
          :class="isMobile ? 'py-1' : ''"
        >
          <base-info
            :title="$t('dashboard.portfolio')"
            :value="$t('dashboard.notUploaded')"
            :isUploaded="false"
            :isFile="true"
          ></base-info>
        </v-col>

        <!-- portfolio -->
        <v-col
          v-for="(porfolio, i) in data.portfolio"
          :key="i"
          lg="2"
          md="3"
          cols="12"
          :class="isMobile ? 'py-1' : ''"
        >
          <base-info
            :title="$t('dashboard.portfolio')"
            :value="porfolio ? $t('dashboard.uploaded') : $t('dashboard.notUploaded')"
            :isUploaded="porfolio ? true : false"
            :isFile="true"
            @click="openLink(porfolio)"
          ></base-info>
        </v-col>
      </v-row>
    </div>

    <!-- social media -->
    <div class="mt-4 bluebgdash" :class="isMobile ? 'pa-3' : 'px-8 py-6'">
      <div class="d-flex align-center justify-space-between mb-3">
        <span class="font-18 font-700">{{
          $t("dashboard.socialMediaLinks")
        }}</span>

        <!-- edit -->
        <div
          class="d-flex align-center cursor-pointer"
          :class="isMobile ? 'mr-1' : 'mr-7'"
          @click="socialDialog = true"
        >
          <img
            class="mx-3"
            :src="require('@/assets/images/dashboard/icons/edit.svg')"
          />
          <span class="darkprimary--text font-16 font-700">{{
            $t("forms.edit")
          }}</span>
        </div>
      </div>

      <v-row :class="isMobile ? '' : 'px-8 mt-4'">
        <v-col
          lg="2"
          md="2"
          cols="12"
          :class="isMobile ? 'py-1' : ''"
          v-for="(social, i) in socials"
          :key="i"
        >
          <base-info
            :title="social.social_name"
            :value="social.doctor_social ? social.doctor_social.value : ''"
          ></base-info>
        </v-col>
      </v-row>
    </div>

    <v-dialog
      v-model="contactDialog"
      :content-class="isMobile ? 'pa-4 radius-5 white' : 'pa-6 white radius-16'"
      max-width="575px"
    >
      <contact-info
        @close="contactDialog = false"
        :isEdit="isEdit"
        :key="contactDialog"
        :info="data"
        @updateData="getContactInfo"
      ></contact-info>
    </v-dialog>

    <v-dialog
      v-model="socialDialog"
      :content-class="isMobile ? 'pa-4 radius-5 white' : 'pa-6 white radius-16'"
      max-width="575px"
    >
      <social-media
        @close="socialDialog = false"
        @updateData="getSocials"
        :isEdit="isEdit"
      ></social-media>
    </v-dialog>
  </div>
</template>

<script>
import BaseInfo from "../../../components/BaseInfo.vue";
import ContactInfo from "../../../components/dialogs/ContactInfo.vue";
import SocialMedia from "../../../components/dialogs/SocialMedia.vue";
import ProfileHead from "../../../components/ProfileHead.vue";
export default {
  components: { ProfileHead, BaseInfo, SocialMedia, ContactInfo },
  data: () => ({
    data: {},
    infos: [],
    socials: [],
    socialDialog: false,
    contactDialog: false,
    isEdit: false,
  }),
  methods: {
    openLink(link) {
      window.open(link, "_blank");
    },
    async getContactInfo() {
      this.contactDialog = false;
      let url;
      if (localStorage.getItem("role") == "doctor") {
        url = "contact/info";
      }
      let { data } = await this.$axios.get(url);
      this.data = data.data;
    },
    async getSocials() {
      this.socialDialog = false;
      let url;
      if (localStorage.getItem("role") == "doctor") {
        url = "social/media";
      }
      let { data } = await this.$axios.get(url);
      this.socials = data.data;
    },
  },
  async created() {
    await this.getContactInfo();
    await this.getSocials();
  },
};
</script>

<style></style>
